.date-picker-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 2rem;
}

.date-picker-button {
    margin-right: 0.5rem;
}

.week-picker-arrow {
    margin: 0 1rem;
}


.custom-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
    border-top: solid 1px var(--off-white-color);
}

.custom-actions .quick-access {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
}

.custom-actions .quick-access > *{
    margin-right: 1rem;
}

.custom-actions .quick-access select {
    min-width: 10rem !important;
}

.entry > * {
    width: 30%;
}

.entry > * > * {
    display: block;
}

.entry .period-name {
    color: var(--primary-color-very-light);
}

.entry .start-date {
    font-size: var(--normal-font-size);
}

.entry .duration {
    text-align: right;
    font-size: var(--normal-font-size-very-large);
}

.entry .activity-type > span {
    font-size: var(--normal-font-size);
    color: var(--primary-color-very-light);
    background-color: var(--off-white-color) !important;
}

@media(max-width: 600px) {

    .entry {
        justify-content: space-between;
    }

    .entry .start-date,
    .entry .duration {
        font-size: var(--normal-font-size);
    }

    .entry .activity-type > span {
        font-size: initial;
    }
}