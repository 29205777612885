.activity-log-entry .duration {
    font-size: var(--normal-font-size-very-large);
}

.activity-log-entry .activity-type > span {
    background-color: var(--primary-color-very-light) !important;
}

.activity-log-entry .activity-tags > span {
    background-color: var(--light-grey-color) !important;
}

.activity-log-entry .activity-name {
    font-size: var(--normal-font-size-large);
}

.activity-log-entry .start-time {
    color: var(--primary-color-very-light);
}