.sub-nav {
    border-bottom: 1px var(--light-grey-color) solid;
}

.sub-nav .nav-link {
    color: var(--normal-text-color-light) !important;
}

.sub-nav .nav-link:hover {
    transform: scale(1.05);
}

.sub-nav .nav-link.active {
    border-bottom: 3px var(--primary-color) solid;
    color: var(--normal-text-color) !important;
}