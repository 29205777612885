.nav-container {
    position: fixed;
    width: 98vw;
    z-index: 1;
}

.account-menu-drop-down {
    background-color: var(--primary-color) !important;
    border-radius: 1.5rem !important;
    border: none !important;
}


.top-nav {
    font-size: 1.25rem;
    font-weight: bold;
    position: relative;
}

.top-nav img {
  height: 2rem;
}

.top-nav ul {
    position: absolute;
    right: 0;
}

.top-nav ul li {
    list-style: none;
    padding: 0 0.5em;
}

.top-nav.horizontal {
    width: 95vw;
    background-color: #ffffff;
    padding: 1rem 0;
    position: relative;
    top: 0;
    left: -2rem;
}

.top-nav.vertical {
    padding: 0.5rem 0;      
    border-bottom: #ffffff solid 2rem;
    position: relative;
    top: 0;
    left: -0.8rem;
}

.top-nav.horizontal,
.top-nav.horizontal ul {
    display: flex;
}

.top-nav.vertical ul {
    background-color: #ffffff;
    width: 100%;
    box-shadow: 0 4px 4px -2px rgba(0 0 0 / 0.2);
}

.top-nav.vertical ul li {
    text-align: right;
    padding: 0.5rem 0;
}

.top-nav.vertical .menu-icon {
    background-color: #ffffff;
    color: var(--primary-color);
    display: flex;
    font-size: 2rem;
    padding: 0.25rem;
    position: absolute;
    top: 0.5rem;
    cursor: pointer;
}

.top-nav.vertical .logo-icon {
    width: 2.75rem;
    height: 2.75rem;
}

.top-nav.vertical .logo-icon,
.top-nav.vertical .logo {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.top-nav.top-nav.vertical .custom-nav {
    position: relative;
    width: 95vw;
    height: 90vh;
    margin: 3.5rem auto 0 auto;
    padding-top: 0.5rem;
    background-color: #ffffff;
}

.top-nav.top-nav.vertical .custom-nav ul {
    padding-top: 3rem;
    padding-left: 0 !important;
    box-shadow: none !important;
}

.top-nav.top-nav.vertical .custom-nav li {
    text-align: center;
}

.top-nav.top-nav.vertical .custom-nav .account-buttons {
    color: var(--normal-text-color-light);
    font-size: 1.1rem;
    position: absolute;
    width: 95vw;
    padding: 0 2rem;
    text-align: center;
    margin: auto;
    bottom: 1.5rem;

    display: flex;
    justify-content: space-between;
}

.top-nav.top-nav.vertical .custom-nav .account-buttons a,
.top-nav.top-nav.vertical .custom-nav .account-buttons a:active {
    color: var(--normal-text-color-light);
    text-decoration: none;
}

.top-nav.top-nav.vertical .custom-nav .account-buttons a:hover {
    color: var(--normal-text-color);
}

.top-nav.top-nav.vertical .custom-nav .account-buttons svg {
    margin-right: 0.5rem;
}

.top-nav a,
.top-nav a:active {
  color: var(--primary-color);
}

.top-nav a:hover,
.top-nav.vertical .menu-icon:hover {
    color: var(--secondary-color);
}


@media (max-width: 1000px) {
    .top-nav.horizontal {
        display: none;
    }

    .top-nav.vertical {
        display: block;
    }
}

@media (min-width: 1001px) {
    .top-nav.horizontal {
        display: flex;
    }

    .top-nav.vertical {
        display: none;
    }
}