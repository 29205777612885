.custom-form .form-fields {
    width: 20rem;
    margin: 0 auto;
}

.custom-form label {
    font-size: 1.2rem;
    display: block;
    color: var(--primary-color);
}

.custom-form .form-group:last-of-type {
    margin-bottom: 3rem;
}

.custom-form .form-group {
    margin-top: 2rem;
}

.custom-form input,
.custom-form select {
    color: var(--normal-text-color) !important;
    min-width: 20rem;
    padding: 0.25rem;
    border-radius: 0.4rem;
    border: 1px var(--light-grey-color) solid;
}

@media (max-width: 500px) {
    .custom-form input,
    .custom-form select {
        min-width: 100%;
    }
}

.custom-form input:active,
.custom-form select:active {
  outline: 2px var(--primary-color-very-light) solid;
}

.custom-form input:focus,
.custom-form select:focus {
  outline: 2px var(--primary-color-very-light) solid;
  color: var(--normal-text-color) !important;
}

.custom-form input,
.custom-form select {
    background-color: var(--off-white-color);
}

.custom-form button {
    margin-right: 1rem !important;
}

.custom-form .block-text {
    margin-top: 0.5rem;
}

.custom-form .submit-button {
    margin-bottom: 2rem;
}

.custom-form .side-by-side-fields {
    max-width: 20rem;
    display: flex;
    justify-content: space-between;
}

.custom-form .side-by-side-fields .form-group {
    max-height: 1rem;
}

.custom-form .side-by-side-fields input,
.custom-form .side-by-side-fields select {
    min-width: 0;
    max-width: 9rem;
}

@media (max-width: 450px) {
    .custom-form .side-by-side-fields input,
    .custom-form .side-by-side-fields select {
        min-width: 0;
        max-width: 7rem;
    }
}

@media (max-width: 375px) {
    .side-by-side-fields {
        display: block;
    }

    .custom-form .side-by-side-fields .form-group {
        max-height: 100%;
    }

    .custom-form .side-by-side-fields input,
    .custom-form .side-by-side-fields select {
        min-width: 100%;
        max-width: 0;
    }

    .custom-form .form input {
        min-width: 90vw !important;
    }

}


/******************************
    Date picker
_______________________________
*******************************/
.react-datepicker__day,
.react-datepicker__current-month ,
.react-datepicker__day-name,
.react-datepicker__time-list-item {
    color: var(--normal-text-color) !important;
}

.react-datepicker__month-container {
    color: var(--normal-text-color) !important;
}

.react-datepicker__header,
.react-datepicker-time__header {
    background-color: var(--off-white-color) !important;
    color: var(--normal-text-color) !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__time-list-item--selected {
    background-color: var(--primary-color-very-light) !important;
    color: #ffffff !important;
}

.react-datepicker__day--disabled,
.react-datepicker__day--disabled.react-datepicker__day--keyboard-selected {
    background-color: #ffffff !important;
    color: var(--secondary-color-very-very-light) !important;
}

.react-datepicker-time__input > input {
    min-width:  4rem !important;
}