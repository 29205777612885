.sub-nav.nav-tabs {
    margin: 2rem 0;
}

.sub-nav.nav-tabs button {
    font-weight: 500;
    color: var(--secondary-color);
    border: none !important;
    border-radius: 0 !important;
}

.sub-nav.nav-tabs button.active {
    border-bottom: 3px var(--primary-color) solid !important;
}